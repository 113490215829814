((window, document) => {
    const wrapper = document.getElementById('flowchat');

    const options = {
        connect: 'Connecting...',
        flow: wrapper.getAttribute('data-flow'),
        icon: 'https://files-manywho-com.s3.amazonaws.com/e422e20c-aeaa-4c5c-b8a9-cc944d796425/chat.svg',
        label: 'Start',
        player: 'default',
        tenant: wrapper.getAttribute('data-tenant'),
        url: wrapper.getAttribute('data-url'),
    };

    if (wrapper.hasAttribute('data-player')) {
        options.player = wrapper.getAttribute('data-player');
    }

    if (wrapper.hasAttribute('data-connect')) {
        options.connect = wrapper.getAttribute('data-connect');
    }

    if (wrapper.hasAttribute('data-icon')) {
        options.icon = wrapper.getAttribute('data-icon');
    }

    if (wrapper.hasAttribute('data-label')) {
        options.label = wrapper.getAttribute('data-label');
    }

    const styles = document.createElement('style') as HTMLStyleElement;
    styles.innerHTML = `
#flowchat, #flowchat button { font-family: system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif; }
#flowchat .flowchat-start {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: hsl(199, 86%, 45%);
    box-shadow: 0 0 20px #0000005c;
    display: flex;
    align-items: center;
    padding: 0.75em 1em;
    font-weight: bold;
    border: none;
    color: white;
    width: auto;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    z-index: 999998;
}
#flowchat .flowchat-start:hover { transform: translateY(-0.25rem); background-color: hsl(199, 86%, 55%); }
#flowchat .flowchat-start > div { padding: 4px 4px 2px 4px; border: 2px solid #ffffff; border-radius: 100%; }
#flowchat .flowchat-start > span { margin-left: 0.5rem; }
#flowchat .flowchat-container {
    border: none;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 2;
    width: 400px;
    height: 600px;
    z-index: 999998;
}
#flowchat .flowchat-container.in { animation: chatSlide 0.3s; }
#flowchat .flowchat-container.out { transform: translateY(700px); animation: chatSlideOut 0.3s; }
@keyframes chatSlide { 0% { transform: translateY(700px) } 100% { transform: translateY(0) } }
@keyframes chatSlideOut { 0% { transform: translateY(0) } 100% { transform: translateY(700px) } }
#flowchat .flowchat-loader {
    border: 1px solid #cccccc;
    background: #ffffff;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}
#flowchat .lds-ellipsis { display: inline-block; position: relative; width: 64px; height: 64px; }
#flowchat .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #404040;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
#flowchat .lds-ellipsis div:nth-child(1) { left: 6px; animation: lds-ellipsis1 0.6s infinite; }
#flowchat .lds-ellipsis div:nth-child(2) { left: 6px; animation: lds-ellipsis2 0.6s infinite; }
#flowchat .lds-ellipsis div:nth-child(3) { left: 26px; animation: lds-ellipsis2 0.6s infinite; }
#flowchat .lds-ellipsis div:nth-child(4) { left: 45px; animation: lds-ellipsis3 0.6s infinite; }
@keyframes lds-ellipsis1 { 0% { transform: scale(0); } 100% { transform: scale(1); } }
@keyframes lds-ellipsis3 { 0% { transform: scale(1); } 100% { transform: scale(0); } }
@keyframes lds-ellipsis2 { 0% { transform: translate(0, 0); } 100% { transform: translate(19px, 0); } }
    `;
    wrapper.appendChild(styles);

    const icon = (Boolean(options.icon)) ? `<div><img src="${options.icon}" height="16" width="18" /></div>` : '';
    const label = (Boolean(options.label)) ? `<span>${options.label}</span>` : '';

    const button = document.createElement('button');
    button.innerHTML = icon + label;
    button.id = 'flowchat-start';
    button.className = 'flowchat-start';
    button.onclick = () => {
        document.getElementById('flowchat-start').style.display = 'none';

        let container = document.getElementById('flowchat-container') as HTMLIFrameElement;

        if (container) {
            container.classList.remove('out');
            container.classList.add('in');
        } else {
            container = document.createElement('iframe');
            container.src = options.url && options.url !== '' ?
                `${options.url}&isEmbedded=true` :
                `https://flow.boomi.com/${options.tenant}/play/${options.player}?flow-id=${options.flow}&isEmbedded=true`;
            container.id = 'flowchat-container';
            container.className = 'flowchat-container in';
            wrapper.appendChild(container);

            const loader = document.createElement('div');
            loader.id = 'flowchat-loader';
            loader.className = 'flowchat-container in flowchat-loader';
            loader.innerHTML = `<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div><p>${options.connect}</p>`;
            wrapper.appendChild(loader);
        }
    };
    wrapper.appendChild(button);

    window.addEventListener('message', (e) => {
        if (e.origin === 'https://flow.manywho.com' || e.origin === 'https://flow.boomi.com') {
            switch (e.data) {
                case 'loaded':
                    document.getElementById('flowchat-loader').remove();
                    break;

                case 'close-chat':
                    document.getElementById('flowchat-start').style.display = 'flex';
                    const container = document.getElementById('flowchat-container');
                    container.classList.remove('in');
                    container.classList.add('out');
                    break;
            }
        }
    });

})(window, document);
